<template>
  <b-container class="">
    <b-col v-if="fullReport">
      <b-row class="m-0">
        <b-col
          v-if="this.hasPermition('r_rel_processos')"
          class="card orange-card p-0 "
        >
          <router-link
            id="bg-card1"
            class="d-flex"
            :to="{ name: 'relatorioProcessos' }"
          >
            <span class="card-label align-self-end">Processos</span>
          </router-link>
        </b-col>

        <b-col
          v-if="this.hasPermition('r_rel_esterilizacao')"
          class="card dark-green-card p-0 mx-4 "
        >
          <router-link
            id="bg-card2"
            class="d-flex"
            :to="{ name: 'relatorioEsterilizacao' }"
          >
            <span class="card-label align-self-end">Esterilização/ Termodesinf.</span>
          </router-link>
        </b-col>

        <b-col
          v-if="this.hasPermition('r_rel_mat_opme')"
          class="card light-green-card p-0 "
        >
          <router-link
            id="bg-card3"
            class="d-flex"
            :to="{ name: 'relatorioEntradasOpme' }"
          >
            <span class="card-label align-self-end">Materiais OPME</span>
          </router-link>
        </b-col>
      </b-row>

      <b-row class="m-0 mt-4">
        <b-col
          v-if="this.hasPermition('r_rel_aborto')"
          class="card dark-green-card p-0"
        >
          <router-link
            id="bg-card4"
            class="d-flex"
            :to="{ name: 'relatorioAbortoDeProcessos' }"
          >
            <span class="card-label align-self-end">Aborto de processos</span>
          </router-link>
        </b-col>

        <b-col
          v-if="this.hasPermition('r_rel_nao_conformidade')"
          class="card light-green-card p-0 mx-4"
        >
          <router-link
            id="bg-card5"
            class="d-flex"
            :to="{ name: 'relatorioNaoConformidades' }"
          >
            <span class="card-label align-self-end">Não conformidades</span>
          </router-link>
        </b-col>

        <b-col
          v-if="this.hasPermition('r_rel_mat_medico')"
          class="card orange-card p-0"
        >
          <router-link
            id="bg-card6"
            class="d-flex"
            :to="{ name: 'relatorioEntradasMedico' }"
          >
            <span class="card-label align-self-end">Materiais médicos</span>
          </router-link>
        </b-col>
      </b-row>
      <b-row class="m-0 mt-4">
        <b-col
          v-if="this.hasPermition('r_rel_inventario')"
          class="card orange-card p-0"
        >
          <router-link
            id="bg-card7"
            class="d-flex"
            :to="{ name: 'relatorioInventario' }"
          >
            <span class="card-label align-self-end">Inventário de Materiais</span>
          </router-link>
        </b-col>

        <b-col
          v-if="this.hasPermition('r_rel_ciclos_forcados')"
          class="card dark-green-card p-0 mx-4 "
        >
          <router-link
            id="bg-card8"
            class="d-flex"
            :to="{ name: 'relatorioEncerramentosForcados' }"
          >
            <span class="card-label align-self-end">Ciclos com encerramentos forçados</span>
          </router-link>
        </b-col>

        <b-col
          v-if="this.hasPermition('r_rel_mat_med_parados')"
          class="card orange-card p-0"
        >
          <router-link
            id="bg-card9"
            class="d-flex"
            :to="{ name: 'relatorioMedicoParados' }"
          >
            <span class="card-label align-self-end">Relatório de materiais médicos parados</span>
          </router-link>
        </b-col>
      </b-row>
      <b-row class="m-0 mt-4">
        <b-col
          v-if="this.hasPermition('r_rel_emprestimos')"
          class="card solo-card orange-card p-0"
        >
          <router-link
            id="bg-card10"
            class="d-flex"
            :to="{ name: 'relatorioEmprestimos' }"
          >
            <span class="card-label align-self-end">Empréstimo de Materiais</span>
          </router-link>
        </b-col>
        <b-col
          v-if="this.hasPermition('r_rel_mat_parados')"
          class="card orange-card p-0 mx-4"
        >
          <router-link
            id="bg-card11"
            class="d-flex"
            :to="{ name: 'relatorioMateriaisParados' }"
          >
            <span class="card-label align-self-end">Relatório de materiais parados</span>
          </router-link>
        </b-col>
        <b-col
          v-if="this.hasPermition('r_rel_mat_receb_aviso')"
          class="card light-green-card p-0"
        >
          <router-link
            id="bg-card3"
            class="d-flex"
            :to="{ name: 'relatorioRecebimentoMateriaisComAviso' }"
          >
            <span class="card-label align-self-end">Relatório de materiais recebidos com aviso</span>
          </router-link>
        </b-col>
      </b-row>
    </b-col>
    <b-col v-else>
      <b-row class="m-0">
        <b-col
          class="card orange-card p-0"
          v-if="this.hasPermition('r_rel_processos')"
        >
          <router-link
            id="bg-card1"
            class="d-flex"
            :to="{ name: 'relatorioProcessos' }"
          >
            <span class="card-label align-self-end">Processos</span>
          </router-link>
        </b-col>

        <b-col
          v-if="this.hasPermition('r_rel_esterilizacao')"
          class="card dark-green-card p-0 mx-4 "
        >
          <router-link
            id="bg-card2"
            class="d-flex"
            :to="{ name: 'relatorioEsterilizacao' }"
          >
            <span class="card-label align-self-end">Esterilização/ Termodesinf.</span>
          </router-link>
        </b-col>
      </b-row>

      <b-row class="m-0 mt-4">
        <b-col
          v-if="this.hasPermition('r_rel_aborto')"
          class="card dark-green-card p-0"
        >
          <router-link
            id="bg-card4"
            class="d-flex"
            :to="{ name: 'relatorioAbortoDeProcessos' }"
          >
            <span class="card-label align-self-end">Aborto de processos</span>
          </router-link>
        </b-col>

        <b-col
          v-if="this.hasPermition('r_rel_nao_conformidade')"
          class="card light-green-card p-0 mx-4">
          <router-link
            id="bg-card5"
            class="d-flex"
            :to="{ name: 'relatorioNaoConformidades' }"
          >
            <span class="card-label align-self-end">Não conformidades</span>
          </router-link>
        </b-col>
      </b-row>
      <b-row class="m-0 mt-4">
        <b-col
          v-if="this.hasPermition('r_rel_inventario')"
          class="card orange-card p-0">
          <router-link
            id="bg-card7"
            class="d-flex"
            :to="{ name: 'relatorioInventario' }"
          >
            <span class="card-label align-self-end">Inventário de Materiais</span>
          </router-link>
        </b-col>

        <b-col
          v-if="this.hasPermition('r_rel_inventario')"
          class="card orange-card p-0 mx-4">
          <router-link
            id="bg-card7"
            class="d-flex"
            :to="{ name: 'relatorioItens' }"
          >
            <span class="card-label align-self-end">Inventário de instrumentos</span>
          </router-link>
        </b-col>

        <b-col
          v-if="this.hasPermition('r_rel_ciclos_forcados')"
          class="card dark-green-card p-0 mr-4">
          <router-link
            id="bg-card8"
            class="d-flex"
            :to="{ name: 'relatorioEncerramentosForcados' }"
          >
            <span class="card-label align-self-end">Ciclos com encerramentos forçados</span>
          </router-link>
        </b-col>
      </b-row>
    </b-col>

</b-container>
</template>

<script>
import login from '@/services/login';

export default {
  name: 'Relatorios',
  components: {},
  data() {
    return {
      fullReport: null,
    };
  },

  mounted() {
    this.fullReport = Boolean(process.env.VUE_APP_PROJECT === 'materdei');
  },
  methods: {
    hasPermition(permition) {
      return login.verifyPermissions([permition]) || login.verifyPermissions(['r_visualizar']);
    },
  },
};
</script>

<style lang="scss" scoped>
.hidden {
  visibility: hidden;
}
.card{
  min-height: 210px;

  border: 0;
  border-radius: 5px;
}

.orange-card {
  background-color: rgba(255, 100, 0, 0.84);
}

.dark-green-card {
  background-color: #209F85;
}

.light-green-card {
  background-color: #21CCA9;
}

.card .card-label {
  color: #fff;

  font-style: normal;
  font-weight: 500;
  font-size: 29px;
  padding: 24px;
}

#bg-card1 {
  background-image: url("../../assets/img/relatorioprocessos.svg");
  background-size: cover;
  height: 100%;
  width: 100%;
}

#bg-card2 {
  background-image: url("../../assets/img/relatorioesterilizacao.svg");
  background-size: cover;
  height: 100%;
  width: 100%;
}

#bg-card3 {
  background-image: url("../../assets/img/relatoriomatopme.svg");
  background-size: cover;
  height: 100%;
  width: 100%;
}

#bg-card4 {
  background-image: url("../../assets/img/relatorioAbortoProcessos.svg");
  background-size: cover;
  height: 100%;
  width: 100%;
}

#bg-card5 {
  background-image: url("../../assets/img/relatorionaoconformidades.svg");
  background-size: cover;
  height: 100%;
  width: 100%;
}

#bg-card6 {
  background-image: url("../../assets/img/relatoriomatmedicos.svg");
  background-size: cover;
  height: 100%;
  width: 100%;
}

#bg-card7 {
  background-image: url("../../assets/img/relatorioInventario.svg");
  background-size: cover;
  height: 100%;
}

#bg-card8 {
  background-image: url("../../assets/img/relatorioAbortoProcessos.svg");
  background-size: cover;
  height: 100%;
  width: 100%;
}

#bg-card9 {
  background-image: url("../../assets/img/relatoriomatmedicos.svg");
  background-size: cover;
  height: 100%;
  width: 100%;
}

#bg-card10 {
  background-image: url("../../assets/img/relatorioprocessos.svg");
  background-size: cover;
  height: 100%;
  width: 80%;
}

#bg-card11 {
  background-image: url("../../assets/img/relatorioAbortoProcessos.svg");
  background-size: cover;
  height: 100%;
  width: 100%;
}

</style>
